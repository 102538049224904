import React, { useState, useEffect } from 'react';
import { Heart, Star, Sparkles, Monitor, Globe, Brain, ArrowLeft, ArrowRight } from 'lucide-react';

const CuteTimelineApp = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(null);

  // Ses efektleri için Audio nesneleri oluştur
  const nextSound = new Audio('/sounds/next.mp3');
  const prevSound = new Audio('/sounds/prev.mp3');

  // Ses seviyesini ayarla
  nextSound.volume = 0.5;
  prevSound.volume = 0.5;

  const timelineData = [
    {
      year: "1950-1970",
      icon: Monitor,
      title: "Bilgisayarların Doğuşu",
      description: "İlk bilgisayarların heyecan verici doğuş dönemi ✨",
      positive: [
        "Hesaplama işlemlerinin hızlanması 🚀",
        "Veri depolama kapasitesinin artması 💾",
        "Bilimsel araştırmaların kolaylaşması 🔬"
      ],
      negative: [
        "Yüksek maliyet 💰",
        "Sınırlı erişim 🔒",
        "Teknolojik bağımlılığın başlaması ⚠️"
      ],
      color: "pink"
    },
    {
      year: "1970-1990",
      icon: Star,
      title: "Kişisel Bilgisayar Devrimi",
      description: "Bilgisayarlar artık evlerimizde! 🏠",
      positive: [
        "Bilgisayarların evlere girmesi 🏡",
        "Yazılım sektörünün gelişmesi 💻",
        "İş verimliliğinin artması 📈"
      ],
      negative: [
        "Geleneksel mesleklerin değişimi 🔄",
        "Siber güvenlik sorunları 🛡️",
        "Sosyal ilişkilerin zayıflaması 👥"
      ],
      color: "purple"
    },
    {
      year: "1990-2010",
      icon: Globe,
      title: "İnternet Çağı",
      description: "World Wide Web dünyayı birleştiriyor 🌐",
      positive: [
        "Bilgiye hızlı erişim 📚",
        "E-ticaretin gelişmesi 🛒",
        "Küresel iletişimin artması ✉️"
      ],
      negative: [
        "Dijital uçurum 🌍",
        "Siber suçların artması 🚫",
        "Bilgi kirliliği ⚠️"
      ],
      color: "blue"
    },
    {
      year: "2010-2024",
      icon: Brain,
      title: "Mobil ve Yapay Zeka Dönemi",
      description: "Teknoloji artık her yerde! 📱✨",
      positive: [
        "Mobil teknolojilerle kolay erişim 📱",
        "Yapay zeka ile inovasyon 🤖",
        "Yeni iş modelleri 💡"
      ],
      negative: [
        "Veri gizliliği endişeleri 🔐",
        "İş gücü değişimleri 💼",
        "Teknoloji bağımlılığı 📵"
      ],
      color: "yellow"
    }
  ];

  const getColor = (color) => {
    const colors = {
      pink: "hover:shadow-pink-200",
      purple: "hover:shadow-purple-200",
      blue: "hover:shadow-blue-200",
      yellow: "hover:shadow-yellow-200"
    };
    return colors[color];
  };

  const handlePrevious = () => {
    if (activeIndex > 0) {
      prevSound.currentTime = 0; // Sesi başa sar
      prevSound.play();
      setActiveIndex(prev => prev - 1);
    }
  };

  const handleNext = () => {
    if (activeIndex < timelineData.length - 1) {
      nextSound.currentTime = 0; // Sesi başa sar
      nextSound.play();
      setActiveIndex(prev => prev + 1);
    }
  };

  const currentPeriod = timelineData[activeIndex];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-pink-50 py-12 px-4">
      <div className="max-w-6xl mx-auto">
        <header className="text-center mb-16">
          <h1 className="text-5xl font-bold text-gray-800 mb-4 relative inline-block">
            Dijitalleşmenin Tarihsel Süreci
            <Heart className="absolute -top-6 -right-8 text-pink-400" size={24} />
          </h1>
          <p className="text-gray-600 text-xl">
            Hazırlayan: Esra Açıkgöz 
            <Sparkles className="inline ml-2 text-yellow-400" size={20} />
          </p>
        </header>

        <div className="relative mb-12">
          <div className="flex justify-between items-center mb-8">
            <button 
              onClick={handlePrevious}
              className="p-4 rounded-full bg-white shadow-lg hover:shadow-xl transition-all transform hover:-translate-x-1"
              disabled={activeIndex === 0}
            >
              <ArrowLeft size={24} className="text-gray-600" />
            </button>
            
            <div className="text-center">
              <h2 className="text-4xl font-bold text-gray-800 mb-2">{currentPeriod.year}</h2>
              <p className="text-gray-600">{currentPeriod.description}</p>
            </div>
            
            <button 
              onClick={handleNext}
              className="p-4 rounded-full bg-white shadow-lg hover:shadow-xl transition-all transform hover:translate-x-1"
              disabled={activeIndex === timelineData.length - 1}
            >
              <ArrowRight size={24} className="text-gray-600" />
            </button>
          </div>

          <div className="bg-white rounded-3xl p-8 shadow-xl transition-all duration-300 hover:shadow-2xl">
            <div className="flex items-center gap-4 mb-8">
              <div className="p-4 rounded-2xl bg-gray-100 transition-all duration-300 transform hover:scale-110">
                <currentPeriod.icon size={32} className="text-gray-600" />
              </div>
              <h3 className="text-3xl font-bold text-gray-800">{currentPeriod.title}</h3>
            </div>

            <div className="grid md:grid-cols-2 gap-8">
              <div 
                className={`bg-green-50 p-6 rounded-2xl transition-all duration-300 transform hover:-translate-y-1 ${
                  isHovering === 'positive' ? 'scale-105' : ''
                }`}
                onMouseEnter={() => setIsHovering('positive')}
                onMouseLeave={() => setIsHovering(null)}
              >
                <h4 className="text-xl font-semibold text-green-600 mb-4 flex items-center gap-2">
                  <Heart size={20} />
                  Olumlu Etkiler
                </h4>
                <ul className="space-y-4">
                  {currentPeriod.positive.map((item, index) => (
                    <li key={index} className="flex items-start gap-3 transition-all duration-300 hover:translate-x-2">
                      <span className="text-green-400 mt-1">•</span>
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div 
                className={`bg-red-50 p-6 rounded-2xl transition-all duration-300 transform hover:-translate-y-1 ${
                  isHovering === 'negative' ? 'scale-105' : ''
                }`}
                onMouseEnter={() => setIsHovering('negative')}
                onMouseLeave={() => setIsHovering(null)}
              >
                <h4 className="text-xl font-semibold text-red-600 mb-4 flex items-center gap-2">
                  <Star size={20} />
                  Olumsuz Etkiler
                </h4>
                <ul className="space-y-4">
                  {currentPeriod.negative.map((item, index) => (
                    <li key={index} className="flex items-start gap-3 transition-all duration-300 hover:translate-x-2">
                      <span className="text-red-400 mt-1">•</span>
                      <span className="text-gray-700">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-4 mt-8">
            {timelineData.map((_, index) => (
              <button
                key={index}
                onClick={() => setActiveIndex(index)}
                className={`w-3 h-3 rounded-full transition-all duration-300 transform hover:scale-150 ${
                  index === activeIndex 
                    ? 'bg-pink-500 scale-125'
                    : 'bg-gray-300'
                }`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CuteTimelineApp;