import CuteTimelineApp from './components/CuteTimelineApp';

function App() {
  return (
    <div className="App">
      <CuteTimelineApp />
    </div>
  );
}

export default App;